import utils from '@/views/auth/Portal/PortalStepperItems/Utils';
import { SHIFT_REJECTED } from './../../../services/FactService';
import { IShiftResult } from './../../../interfaces/IQuotation';
import QuoteProduct from '@/models/QuoteProduct';
import {GetterTree} from 'vuex';
import { IQuotation, IRoot, IQuotedPrice, IShiftProductData, IQuotationProduct } from '@/interfaces';
import _UniqBy from 'lodash/uniqBy';
import _GroupBy from 'lodash/groupBy';
import _Filter from 'lodash/filter';
import _Get from 'lodash/get';
import _Merge from 'lodash/merge';
import _SortBy from 'lodash/sortBy';
import _Some from 'lodash/some';
import _Find from 'lodash/find';
import _ForEach from 'lodash/forEach';
import Util from '@/utils/Util';
import moment from 'moment';
import _ from 'lodash';
import stringHash from 'string-hash';

interface IPatchedManual {
  id: string;
}

const gettersImp: GetterTree<IQuotation, IRoot> = {
  isInSectionGroup: (state: IQuotation, getters: any, rootState: IRoot) => (sectionName: string, fact: any) => {
    const sectionGroups: string[][] = rootState.app.config.shiftMappings.SECTION_GROUPS || {};

    const childOf = fact.childOf.replace(/:\d+\./, ':x.');
    const section = sectionGroups && sectionGroups[sectionName] &&
      ( sectionGroups[sectionName].includes(childOf) ||
        sectionGroups[sectionName].includes(fact.id) ||
        sectionGroups[sectionName].includes(fact._customID)
      )
      && sectionName || childOf || '';
    return section === sectionName;
  },

  getSortedQuotationProduct: (state: IQuotation) => () => {
    return _SortBy(state.products, ['order', 'index']);
  },

  getQuotedProductIDByProductCode: (state: IQuotation) => (productCode: string): string => {
    const product: IShiftProductData | undefined = _Find(state.products, { code: productCode });
    return product ? product.id : '';
  },

  getFactSynonyms: (state: IQuotation, getters: any, rootState: IRoot) => () => {
    // CMS CONFIG: synonym of facts
    const synonyms: string[][] = rootState.app.config.shiftMappings.SYNONYMS || [];
    const allFacts: any[] = getters.getCustomAllFacts();
    const groupedFacts: any[] = _.map(_GroupBy(allFacts, 'id'), (items, name) => items);

    const factSynonyms: any = {};
    for (const factItems of groupedFacts) {
      _.forEach(factItems, (fact) => {
        if (factSynonyms.hasOwnProperty(fact._customID)) {
          return;
        }
        let group = synonyms.find((g) => g.find((s) => s.match(/^PRODUCT:.*#/) ? s === fact._customID : s === fact.id));
        // Format all fact id to be product specific
        group = group && _.flattenDeep(_.map(group, (s) => s.match(/^PRODUCT:.*#/) ? s : _.map(state.products, (p) => `PRODUCT:${p.code}#${s}`)));
        // DISABLE: automatic link of facts with same fact.id
        if (!group || !group.length) {
          return;
        }
        // Auto link
        // if (factItems.length > 1) {
          // let baseGroup = _.map(factItems, (factItem) => factItem._customID);
          // baseGroup = _Filter(baseGroup, (g) => g !== fact._customID);
          // if (!group) {
          //   group = [];
          // }
          // group = _.uniq([..._.flattenDeep(_.map(group, (s) => s.match(/^PRODUCT:.*#/) ? s : _.map(state.products, (p) => `PRODUCT:${p.code}#${s}`))), ...baseGroup]);
        // }
        factSynonyms[fact._customID] = group && group.filter((s) => s !== fact._customID);
      });
    }
    return factSynonyms;
  },
  getCustomAllFacts: (state: IQuotation) => (): any[] => {
    let customAllFacts: any[] = [];
    for (const prd in state.products) {
      if (Object.prototype.hasOwnProperty.call(state.products, prd)) {
        const quotationProduct = state.products[prd];
        customAllFacts = [...customAllFacts , ...quotationProduct.customAllFacts];
      }
    }

    return customAllFacts;
  },
  getCoverageDetailSections: (state: IQuotation, getters: any, rootState: IRoot) => (): string[] => {
    // CMS CONFIG: facts that omitted in Coverage Detail
    const omitted: string[] = rootState.app.config.shiftMappings.OMITTED_COVERAGE_DETAILS_SECTIONS || [];
    // CMS CONFIG: facts orders
    const order: string[] = rootState.app.config.shiftMappings.COVERAGE_DETAILS_SECTIONS_ORDER || [];

    const manual: any = rootState.app.config.shiftMappings.SECTION_GROUPS || {};
    const patchedManual: IPatchedManual[] = [];
    for (const m of Object.keys(manual)) {
      patchedManual.push({id: m});
    }
    const customAllFacts = getters.getCustomAllFacts();
    // filtered with non group and pricing
    const customAllFactsFiltered = customAllFacts.filter((f) => f.type === 'GROUP' && f.childOf === '' && f.usedBy.some((u) => u !== 'PRICING'));
    const filtered: any = customAllFactsFiltered.filter((r) => !patchedManual.some((m) => manual[m.id].includes(r.id)));
    const unique: any[] = _UniqBy(filtered.concat(patchedManual), 'id');

    const sections: string[] = [];

    for (const name of order) {
      if (unique.some((u: any) => u.id === name)) {
        if ((!rootState.app.isRenewal || name !== 'renewal') && !omitted.includes(name)) {
          sections.push(name);
        }
      }
    }
    for (const rest of unique) {
      if (! sections.some((s: any) => s === (rest as any).id)) {
        if ((!rootState.app.isRenewal || (rest as any).id !== 'renewal') && !omitted.includes((rest as any).id)) {
          sections.push((rest as any).id);
        }
      }
    }

    return sections;
  },

  getCoverageDetailFacts: (state: IQuotation, getters: any) => () => {

    const USE_FACT: string = 'POLICY';
    const sections = getters.getCoverageDetailSections();

    const allFacts: any[] = getters.getCustomAllFacts();
    const uniqueFacts: any[] = _UniqBy(allFacts, 'id');

    const genericFactIds = utils.getGenericFactsConfig('coverage.facts') || [];

    const deductedGenericFacts = uniqueFacts.filter((f: any) => !genericFactIds.includes(f.id));

    const retFacts: any[] = getters.dedupFacts(deductedGenericFacts);

    const result = {};
    sections.forEach((s: string) => {
      result[s] = retFacts.filter((f: any) => f.childOf.startsWith(s) || getters.isInSectionGroup(s, f));
    });

    return result;
  },

  getAllProductLevelFacts: (state: IQuotation) => () => {
    const allFacts: any[] = [];

    Object.keys(state.products).forEach((productID: string) => {
      const tree = state.products[productID].customProductTree;
      allFacts.push(tree.allFacts);
    });
    return _.flatten(allFacts);
  },

  getAssetLevelFacts: (state: IQuotation, getters: any) => () => {
    let allFacts: any[] = [];
    Object.keys(state.products).forEach((productID: string) => {
      const tree = state.products[productID].customProductTree;
      if (tree && tree.components) {
        _.forEach(tree.components, (comp: any) => {
          if (comp) {
            _.forEach(comp.assets, (asset: any) => {
                if (asset) {
                allFacts = [...allFacts, ...asset.policyFacts];
              }
            });
          }
        });
      }
    });
    return getters.dedupFacts(allFacts);
  },

  getAllComponentLevelFacts: (state: IQuotation, getters: any, rootState: IRoot) => () => {
    const allFacts: any[] = [];

    Object.keys(state.products).forEach((productID: string) => {
      const tree = state.products[productID].customProductTree;
      for (const component of tree.components) {
        allFacts.push(component.allFacts);
        for (const asset of component.assets) {
          allFacts.push(asset.allFacts);
        }
      }
    });
    return _.flatten(allFacts);
  },

  dedupFacts: (state: IQuotation, getters: any, rootState: IRoot) => (allFacts: any[]): any[] => {
    const retFacts: any[] = [];
    const factSynonyms: any = {};
    const synonyms: string[][] = rootState.app.config.shiftMappings.SYNONYMS || [];

    for (const fact of allFacts) {

      if (factSynonyms.hasOwnProperty(fact._customID)) {
        continue;
      }

      const group = synonyms.find((g: string[]) => g.find((s: string) => s.match(/^PRODUCT:.*#/) ? s === fact._customID : s === fact.id));
      factSynonyms[fact._customID] = group && group.filter((s) => s !== fact._customID);

      // overwriting mandatory
      if (allFacts && fact.id && !fact.mandatory) {
        fact.mandatory = allFacts.some((f: any) => f.mandatory && f.id === fact.id);
      }

      // pushing
      if (!group || factSynonyms[fact._customID] &&
        !factSynonyms[fact._customID].some((s: string) => retFacts.some( (f) => f._customID === s))
        ) {
        retFacts.push(fact);
      }
    }
    return retFacts;
  },

  hasUnderwritingFactsByProductID: (state: IQuotation, getters: any, rootState: IRoot) => (productID: string): boolean => {
    const customProductTree = state.products[productID];
    if (!customProductTree) {
      return false;
    }

    const omitted: string[] = rootState.app.config.shiftMappings.OMITTED_VERIFICATION_FACTS || [];
    const customUnderwritingFacts: any[] = customProductTree.customUnderwritingFacts;

    const genericFactIds = utils.getGenericFactsConfig('verification.facts') || [];
    const deductedGenericFacts = customUnderwritingFacts.filter((f: any) => !genericFactIds.includes(f.id));
    for (const fact of deductedGenericFacts) {
      if (_.get(fact, 'type') !== 'GROUP' && !omitted.includes(fact.id) && fact.usedBy && !fact.usedBy.some((u)  => u.type === 'POLICY')) {
        return true;
      }
    }

    return false;
  },

  getUnderwritingFactsByProductID: (state: IQuotation, getters: any, rootState: IRoot) => (productID: string) => {
    const customProductTree = state.products[productID];
    if (!customProductTree) {
      return [];
    }

    const omitted: string[] = rootState.app.config.shiftMappings.OMITTED_VERIFICATION_FACTS || [];
    const customUnderwritingFacts: any[] = customProductTree.customUnderwritingFacts;
    const withoutOmitted = customUnderwritingFacts.filter((fact: any) => !omitted.includes(fact.id));
    const uwOnlyFacts = withoutOmitted.filter((fact: any) => !fact.usedBy.some((u) => u.type === 'POLICY'));
    const factGroups =  getters.getFactGroups();

    const genericFactIds = utils.getGenericFactsConfig('verification.facts') || [];
    const deductedGenericFacts = uwOnlyFacts.filter((f: any) => !genericFactIds.includes(f.id));

    const omitAutoGroups = deductedGenericFacts.filter((fact: any) => _Get(fact, 'type') !== 'GROUP' || factGroups[fact._customID] || factGroups[fact.id]);
    return omitAutoGroups;
  },

  getFactsByProductIDAndFactID: (state: IQuotation) => (productID: string, factID: string) => {
    const product = state.products[productID];
    const facts = product.customAllFacts;
    const fact = facts.find((f) => f.id === factID);
    return product && fact ? fact : null;
  },

  getFactByFactID: (state: IQuotation) => (factID: string) => {
    for (const productID of Object.keys(state.products)) {
      const facts = state.products[productID].customAllFacts;
      const fact = facts.find((f) => f.id === factID);
      if (fact) {
        return fact;
      }
    }
  },

  getFactValueByFactID: (state: IQuotation) => (factID: string): string => {
    for (const productID of Object.keys(state.products)) {
      const facts = state.products[productID].customAllFacts;
      const fact = facts.find((f) => f.id === factID);
      if (fact) {
        return fact.currentValue;
      }
    }

    return '';
  },

  getFactCMSFormatted: (state: IQuotation, getters: any, rootState: IRoot) => async (factID: string) => {
    let keys: string[] = [];
    const order = rootState.app.config.shiftMappings.PRODUCT_PRIORITY_ORDER;
    if (order) {
      order.forEach((code) => {
        _ForEach(state.products, (product, id) => {
          if (product.code === code) {
            keys.push(id);
            return false;
          }
        });
      });
    } else {
      keys = Object.keys(state.products);
    }

    for (const productID of keys) {
      const facts = state.products[productID].customAllFacts;
      const fact = facts.find((f) => f.id === factID);
      if (fact) {
        return {
          ...fact,
          name: await Util.markdownFormatted(fact.name)
        };
      }
    }
  },

  getConfigFactTypeByFactType: (state: IQuotation) => (factType: string) => {
    const NON_CHARACTER_REGEX = /[^a-zA-Z_]/g;
    return QuoteProduct.FACT_TYPE_MAP[factType.replace(NON_CHARACTER_REGEX, '').toUpperCase()];
  },

  getFactGroups: (state: IQuotation, getters: any, rootState: IRoot) => (productID: string) => {
    const namedFactGroups: any = rootState.app.config.shiftMappings.NAMED_FACT_GROUPS || {};
    const namedFactGroupsCoverage: any = rootState.app.config.shiftMappings.NAMED_FACT_GROUPS_COVERAGE || {};

    return {
      ...namedFactGroups,
      ...namedFactGroupsCoverage,
    };
  },

  getQuotedPrices: (state: IQuotation, getters: any, rootState: IRoot) => (): IQuotedPrice[] => {
    const products: IShiftProductData[] = _SortBy(state.products, ['index']);
    const quotedPrices: IQuotedPrice[] = [];

    products.forEach((p: IShiftProductData) =>  {
      const price: any = p.price;
      const cmsProduct = rootState.cms.products.find((cmsPrd: any) => cmsPrd.fields.metadata.some((m: any) => m === 'PROD_CODE:' + p.code));
      const productIcon: string = _Get(cmsProduct, 'fields.icon', '');
      const productTitle: string = _Get(cmsProduct, 'fields.title', '');

      const isTotalProductPriceDisplayed: boolean = price ? price.totalPrice !== price.beforeProration : false;
      const isReviewRequired: boolean = p.isReviewRequired;
      const valid: boolean = price ? price.valid : false;
      const totalPrice: number = price ? price.totalPrice : 0;
      const totalProductPrice: number = price && price.valid ? price.beforeProration : 0;
      const currentPrice: number = p.currentPrice;
      const basePrice: number = (p && p.price && p.price.buckets && p.price.buckets[0]?.totalPriceBeforeProration) || totalProductPrice;
      const isSeparateCoveragePeriod: boolean = p.isSeparateCoveragePeriod;
      const isFullPriceDisplayed: boolean = isTotalProductPriceDisplayed && !isReviewRequired;
      const coverageDate = !!(p.policyStartDate && p.policyEndDate);
      const coverageDateRange: string = `${moment(coverageDate ? p.policyStartDate : p.coverageStartDate, 'YYYY-MM-DD').format(_.get(rootState.cms, 'theme.dateFormat') || Util.DEFAULT_DATE_FORMAT)} - ${moment(coverageDate ? p.policyEndDate : p.coverageEndDate, 'YYYY-MM-DD').format(_.get(rootState.cms, 'theme.dateFormat') || Util.DEFAULT_DATE_FORMAT)}`;

      quotedPrices.push({
        price,
        productDetail: p,
        isTotalProductPriceDisplayed,
        isReviewRequired,
        totalProductPrice,
        currentPrice,
        isSeparateCoveragePeriod,
        productIcon,
        productTitle,
        isFullPriceDisplayed,
        coverageDateRange,
        valid,
        totalPrice,
        basePrice
      });
    });
    return quotedPrices;
  },

  getTotalQuotedPrice: (state: IQuotation, getters: any) => () => {
    let price: number = 0;
    const quotedPrices: IQuotedPrice[]  = getters.getQuotedPrices();
    quotedPrices.forEach((qPrice: IQuotedPrice) => {
      if (qPrice.valid && !qPrice.isReviewRequired) {
        price += qPrice.totalPrice;
      }
    });
    return price;
  },

  getAllCustomProductTrees: (state: IQuotation) => () => {
    const allTrees: IShiftProductData[] = [];

    Object.keys(state.products).forEach((productID: string) => {
      allTrees.push(state.products[productID].customProductTree);
    });
    return allTrees;
  },

  getProducts: (state: IQuotation) => () => {
    return state.products;
  },

  getPremiumDetailForPardotService: (state: IQuotation) => (isCheckUW: boolean, seperator: string = '|') => {
    const productDetails: string[] = [];

    Object.keys(state.products).forEach((productID: string) => {
      const product: IShiftProductData = state.products[productID];
      const uwStatus: boolean = isCheckUW && (_Get(product.underwriting, 'status') !== 'OK');
      const price: string = product.isReviewRequired || uwStatus   ? 'review' : product.currentPrice.toFixed(2).toString();
      const coverageStartDate: string = product.coverageStartDate || product.policyStartDate || '';
      const detail: string = product.code + seperator + price + seperator + coverageStartDate;
      productDetails.push(detail);
    });

    return productDetails.join(';');
  },

  isUwOkByProductId: (state: IQuotation) => (productID: string, isContinuation: boolean = false): boolean => {
    const product = state.products[productID];
    const underwritingResult = _.get(product, 'underwriting.status');

    if (isContinuation && _.includes(['OK', 'MANUAL'], underwritingResult)) {
      return true;
    }

    if (underwritingResult !== 'OK') {
      return false;
    }

    return _.get(product, 'price.valid') && !_.get(product, 'price.needsManualPricing', true);
  },

  getTotalQuotedPriceToPay: (state: IQuotation, getters: any) => (isContinuation: boolean = false): number => {
    let result: number = 0;
    Object.keys(state.products).forEach((productID: string) => {
      const product: IShiftProductData = state.products[productID];
      if (getters.isUwOkByProductId(product.id, isContinuation)) {
        result += product.price.totalPrice;
      }
    });

    return result;
  },

  getConfirmedTotalQuotedPriceToPay: (state: IQuotation, getters: any) => (isContinuation: boolean = false): number => {
    let result: number = 0;
    Object.keys(state.products).forEach((productID: string) => {
      const product: IShiftProductData = state.products[productID];
      if (getters.isUwOkByProductId(product.id, isContinuation) && getters.isValidShiftResultByProductID(product.id)) {
        result += product.price.totalPrice;
      }
    });

    return result;
  },

  getTotalQuotedTax: (state: IQuotation, getters: any) => (isContinuation: boolean = false): number => {
    let result: number = 0;
    Object.keys(state.products).forEach((productID: string) => {
      const product: IShiftProductData = state.products[productID];
      if (getters.isUwOkByProductId(product.id, isContinuation)) {
        result += product.price.taxAmount;
      }
    });

    return result;
  },

  getConfirmedTotalQuotedTax: (state: IQuotation, getters: any) => (isContinuation: boolean = false): number => {
    let result: number = 0;
    Object.keys(state.products).forEach((productID: string) => {
      const product: IShiftProductData = state.products[productID];
      if (getters.isUwOkByProductId(product.id, isContinuation) && getters.isValidShiftResultByProductID(product.id)) {
        result += product.price.taxAmount;
      }
    });

    return result;
  },

  getShiftResultByProductID: (state: IQuotation) => (productID: string): any => {
    return state.shiftResults.find((result: IShiftResult) => result.product.id === productID);
  },

  isValidShiftResultByProductID: (state: IQuotation, getters: any) => (productID: string): any => {
    const shiftResult = getters.getShiftResultByProductID(productID);
    return !shiftResult || shiftResult.message !== QuoteProduct.SHIFT_STATUS_TYPE.SHIFT_REJECTED;
  },

  getTotalTaxPercentage: (state: IQuotation, getters: any) => (): any => {
    let tax: number = 0;
    const quotedProductIds = Object.keys(state.products);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < quotedProductIds.length; i++) {
      const productID: string = quotedProductIds[i];
      const isValidShiftResult = getters.isValidShiftResultByProductID(productID);
      if (isValidShiftResult) {
        const firstValidQuoteProduct = state.products[productID];
        tax = _Get(firstValidQuoteProduct, 'price.taxPercent', 0);
        break;
      }
    }
    return tax;
  },

  isPriceValidByProductId: (state: IQuotation) => (productID: string, isContinuation: boolean = false): boolean => {
    const product = state.products[productID];
    const uwStatus = _.get(product, 'underwriting.status', 'OK');
    if (isContinuation && _.includes(['OK', 'MANUAL'], uwStatus)) {
      return true;
    }

    if (uwStatus !== 'OK') {
      return false;
    }

    return _.get(product, 'price.valid') && !_.get(product, 'price.needsManualPricing', true);
  },

  isReviewRequired: (state: IQuotation, getters: any) => (): any => {
    for (const id of Object.keys(state.products)) {
      if (state.products[id].price.isReviewRequired) {
        return true;
      }
    }

    return false;
  },

  getProductPriceWithType: (state: IQuotation, getters: any) => (productID: string, priceType: string, isAfterUW: boolean = false): number => {
    const product = state.products[productID];
    const isValid: boolean = getters.isPriceValidByProductId(productID);
    return isAfterUW || isValid ? _.get(product.price, priceType, 0) : NaN;
  },

  getProductPriceToPay: (state: IQuotation, getters: any) => (productID: string): number => {
    const product = state.products[productID];
    const totalPriceInclTax = _.get(product, 'price.totalPriceInclTax', 0);
    const refundInclTax = _.get(product, 'price.refundInclTax', 0);
    return totalPriceInclTax - refundInclTax;
  },

  getPriceBucket: (state: IQuotation, getters: any) => (productID: string, isAfterUW: boolean = false): any[] => {
    const product = state.products[productID];
    const isValid: boolean = getters.isPriceValidByProductId(productID);
    return isAfterUW || isValid ? product.price.buckets : [];
  },

  getCustomFactDetail: (state: IQuotation) => (productID: string, factID: string): any => {
    const product = state.products[productID];
    return product.customAllFacts.find((f: any) => f.id === factID);
  },

  hasSectionFacts: (state: IQuotation, getters: any, rootState: IRoot) => (sectionName: string): boolean => {
    const sectionGroups = _.get(rootState.app.config.shiftMappings, 'SECTION_GROUPS', {});

    const isRealChild = (productID: string, fact: any) => {
      const customFact = getters.getCustomFactDetail(productID, fact.id);
      if (_.get(customFact, 'type.type') === 'GROUP' || !customFact.usedBy.some((u) => u.type === 'POLICY')) {
        return false;
      }
      const childOf = fact.childOf.replace(/:\d+\./, ':x.');
      const reference = _.get(sectionGroups, sectionName) && (sectionGroups[sectionName].includes(childOf) || sectionGroups[sectionName].includes(customFact.id));
      return (childOf === sectionName && !reference) || reference;
    };

    for (const id of Object.keys(state.products)) {
      const tree = state.products[id].customProductTree;
      for (const fact of tree.allFacts) {
        if (isRealChild(id, fact)) {
          return fact;
        }
      }
      for (const comp of tree.components) {
        const compFact = comp.allFacts.find((f) => isRealChild(id, f));
        if (compFact) {
          return compFact;
        }
        for (const asset of comp.assets) {
          const assetFact = asset.allFacts.find((f) => isRealChild(id, f));
          if (assetFact) {
            return assetFact;
          }
        }
      }
    }

    return false;
  },

  getSectionFacts: (state: IQuotation, getters: any, rootState: IRoot) => (sectionName: string): any => {
    const policyFacts: any[] = [];
    for (const id of Object.keys(state.products)) {
      const tree = state.products[id].customProductTree;
      for (const comp of tree.components) {
        for (const compFact of comp.allFacts) {
          if (_.get(compFact, 'type.type') !== 'GROUP' && compFact.usedBy && compFact.usedBy.some((u) => u.type === 'POLICY')) {
            policyFacts.push({...getters.getCustomFactDetail(id, compFact.id), productCode: tree.code});
          }
        }
      }
      for (const fact of tree.allFacts) {
        if (_.get(fact, 'type.type') !== 'GROUP' && fact.usedBy && fact.usedBy.some((u) => u.type === 'POLICY')) {
          policyFacts.push({...getters.getCustomFactDetail(id, fact.id), productCode: tree.code});
        }
      }
    }
    const synonyms = _.get(rootState.app.config.shiftMappings, 'SYNONYMS', []);
    const sectionFacts: any[] = [];

    for (const f of policyFacts) {
      if (getters.isInSectionGroup(sectionName, f)) {
        if (!sectionFacts.some((x) => x.id === f.id)) {
          let found = false;
          synonyms.filter((g) => g.includes(f.id)).forEach((group) => {
            found = found || sectionFacts.some((x) => group.includes(x.id));
          });
          if (!found) {
            sectionFacts.push(f);
          }
        }
      }
    }

    return sectionFacts;
  },

  hasCoverageInsuredType: (state: IQuotation, getters: any, rootState: IRoot) => (insuredType: any): any => {
    return _.includes(_.get(rootState.app.config.shiftMappings, 'OMITTED_INSURED_TYPES_CONFIGURATION', []), insuredType.type);
  },

  insuredTypes: (state: IQuotation, getters: any, rootState: IRoot) => (): any => {
    const insuredTypes: any[] = [];
    let index = 0;
    for (const id of Object.keys(state.products)) {
      const tree = state.products[id].customProductTree;
      _.forEach(tree.components, (comp: any) => {
        _.forEach(comp.insuredTypes, (insuredType: any) => {
          if (getters.hasCoverageInsuredType(insuredType)) {
            insuredTypes.push({
              ...insuredType,
              productId: id,
              comp,
              tree: {
                ...tree,
                id: index,
              }
            });
          }
        });
      });
      index++;
    }
    return insuredTypes;
  },

  getConfigurationFactHashes: (state: IQuotation, getters: any, rootState: IRoot) => (): any => {
    const hashes: any = {};
    const whitelist = rootState.app.config.shiftMappings.SYNONYMS || [];
    const facts = getters.getCustomAllFacts();
    for (const fact of facts) {
      for (const arr in whitelist) {
        if (whitelist[arr].includes(fact.id)) {
          const hash = 'id-' + stringHash(arr);
          if (!hashes[hash]) {
            hashes[hash] = [];
          }
          hashes[hash].push(fact._productCode + '@' + fact.id);
        }
      }
    }
    return hashes;
  },

  getFactValidationData: (state: IQuotation) => (productID: string, validatedFacts: string[] = []) => {
    let result: any[] = [];
    const quotedProduct = state.products[productID];
    const validation =  quotedProduct.validationData;
    const code = quotedProduct.code;

    if (validation && !validation.valid && validation.errors) {
      validation.errors.forEach((e: any) => {
        const error = Object.assign({}, e);
        if (error.code === Util.INVALID_START_DATE_CODE) {
          result = result.filter((r) => r.relatedFacts && r.relatedFacts.includes('COMPONENT:base.base.start'));
          error.relatedFacts = ['COMPONENT:base.base.start'];
        } else if (error.code === Util.INVALID_TERM_LENGTH_CODE) {
          if (!result.some((x) => x.relatedFacts && x.relatedFacts.includes === 'COMPONENT:base.base.start')) {
            error.relatedFacts.push('COMPONENT:base.base.start');
          }
          if (!result.some((x) => x.relatedFacts && x.relatedFacts.includes === 'COMPONENT:base.base.end')) {
            error.relatedFacts.push('COMPONENT:base.base.end');
          }
        } else if (error.code === Util.INVALID_START_BEFORE_END_CODE) {
          result = result.filter((r) => r.relatedFacts && r.relatedFacts.includes('COMPONENT:base.base.end'));
          error.relatedFacts = ['COMPONENT:base.base.end'];
        }
        if (validatedFacts.length) {
          let relatedFacts: any[] = [];
          for (const f of error.relatedFacts) {
            if (f.includes('*')) {
              const wildCardFactRegex = new RegExp('^' + f.replace(/\*/g, '[^\\-]+') + '$');
              relatedFacts = validatedFacts.filter((fact) => wildCardFactRegex.test(fact));
            } else {
              relatedFacts = relatedFacts.concat(f.split(','));
            }
          }
          if (_Some(validatedFacts, (v: string) => relatedFacts.includes(v))) {
            error.relatedFacts = relatedFacts;
            result.push({ productId: code, error });
          }
        } else {
          result.push({ productId: code, error });
        }
      });
    }
    return result;
  },

  // used to check product session validity before quote submission
  isAllQuoteValid: (state: IQuotation, getters: any) => (): boolean => {
    const resultArray: boolean[] = [];

    if (state.products && Object.keys(state.products).length > 0) {
      Object.keys(state.products).forEach((productID: string) => {
        const product: IShiftProductData = state.products[productID];
        resultArray.push(product.validationData.valid);

        // log error when invalid product config
        if (!product.validationData.valid) {
          console.error('Invalid product config: ', product.validationData.errors);
        }
      });
    }

    return resultArray.includes(false) ? false : true;
  },

  hasPastCoverageStartDate: (state: IQuotation, getters: any) => (): boolean => {
    let result = false;
    Object.values(state.products).forEach((product: any) => {
      if (getters.hasPastCoverageStartDateForProductId(product.id)) {
        result = true;
      }
    });
    return result;
  },

  hasPastCoverageStartDateForProductId: (state: IQuotation) => (productId: any): boolean => {
    let result = false;
    const now = new Date();
    const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
    Object.values(state.products).forEach((product: any) => {
      if (product.id === productId && (!product.coverageStartDate || new Date(product.coverageStartDate) < today)) {
        result = true;
      }
    });
    return result;
  },

};

export default gettersImp;
