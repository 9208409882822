import { facts } from './index';
import _Get from 'lodash/get';
import _GroupBy from 'lodash/groupBy';
import _OrderBy from 'lodash/orderBy';
import _IndexOf from 'lodash/indexOf';
import _Filter from 'lodash/filter';
import _Map from 'lodash/map';
import _Find from 'lodash/find';
import _Uniq from 'lodash/uniq';
import _Values from 'lodash/values';
import _IsEmpty from 'lodash/isEmpty';
import _Some from 'lodash/some';
import { IFactSynonyms, IMultiFactUpdatePayload, IShiftResult, IPresetFactPayload } from './../../../interfaces/IQuotation';
import QuoteProduct from '@/models/QuoteProduct';
import {ActionTree, ActionContext} from 'vuex';
import {CarjamService} from '@/services/CarjamService';
import { IFactObject, IQuotation, IRoot, IRawProducts, IRawProduct, IShiftProductData, IBuildPayload, IFactUpdatePayload, IAddInsuredPayload, IRemoveInsuredPayload, ISetPolicyStartDatePayload, IQuotationCache, ISetQuotationProductPayload } from '@/interfaces';
import {
  SET_QUOTATION_PRODUCT,
  DELETE_QUOTATION_PRODUCT,
  RESET_QUOTATION,
  SET_QUOTATION_CACHE,
  DELETE_QUOTATION_CACHE,
  SET_SYNONYMS,
  SET_SHIFT_RESULTS,
  SET_MANUAL_PRICING,
  SET_QUOTATION,
  UPDATE_QUOTATION_FILES,
  REMOVE_QUOTATION_FILES,
  SET_REVIEW_REQUIRED
} from './mutationTypes';
import store from '@/store';
import Util from '@/utils/Util';
import _ from 'lodash';
import {PolicyPartyModel} from 'shift-policy-service-api-client';
import ContentManagementService from '@/services/ContentManagementService';
import PolicyService from '@/services/PolicyService';

const actions: ActionTree<IQuotation, IRoot> = {

  reset({commit}: ActionContext<IQuotation, IRoot>): void {
    commit(RESET_QUOTATION);
  },

  async updateFactUpload({commit, rootState, rootGetters, dispatch}: ActionContext<IQuotation, IRoot>, proposalId?: string): Promise<void> {
    const promiseAll: Array<Promise<any>> = [];

    rootState.proposal.products.forEach((product: any) => {
      const files: any = rootState.quotation.filesToUpload[product.id];
      if (files) {
        Object.values(files).forEach((fileArr: any) => {
          fileArr.forEach((file) => {
              promiseAll.push(
                ContentManagementService.uploadfile(file, rootState.auth.accountId, proposalId ? proposalId : product.policyId).then((res) => {
                  console.log(`Uploaded files successfully - ${res.data?.data?.fileName}`);
                }).catch(() => {
                  console.log('Error Occured');
                })
              );
          });
        });
      }
    });
 },

  updateQuotationFiles({commit, rootState, dispatch}: ActionContext<IQuotation, IRoot>, data: any): void {
    if (data.value.remove) {
      commit(REMOVE_QUOTATION_FILES, {productId: data.fact._productID, file: data.value.selectedFile , id: data.value.id});
    } else {
      commit(UPDATE_QUOTATION_FILES, {productId: data.fact._productID, file: data.value.selectedFile, id: data.value.id});
    }
 },

  async createQuotationProduct({commit, rootState, rootGetters, dispatch}: ActionContext<IQuotation, IRoot>, productCode: string) {
    const renewalConfigs: any = _Get(rootState.app.renewalResponse, 'configs', {});
    const { id, code, definition }: any = rootGetters['products/getAllProductsWithLatestVersion']().find((raw: IRawProduct) => raw.code === productCode);
    let productID: any = id;
    const renewalConfig = renewalConfigs[productID];

    if (renewalConfig) {
      const quoteProduct = new QuoteProduct(id, code, definition, 100, new Date().getTime(), renewalConfigs[productID] || '');
      quoteProduct.init().then( async (quoted: IShiftProductData) => {
          commit(SET_QUOTATION_PRODUCT, quoted);
          dispatch('proposal/addProduct', {id, code, definition}, { root: true });
          dispatch('setSynonyms').then(() => dispatch('updateAllProductSynonyms'));
      });
    } else {
      productID = await dispatch('setQuotationProduct', { productCode });
      dispatch('setSynonyms').then(() => dispatch('updateProductSynonymsByProductID', productID));
    }
  },

  deleteQuotationProduct({commit, dispatch}: ActionContext<IQuotation, IRoot>, productID: string): void {
    commit(DELETE_QUOTATION_PRODUCT, productID);
    commit(DELETE_QUOTATION_CACHE, productID);

    dispatch('setSynonyms');
  },

  setSynonyms({commit, getters}: ActionContext<IQuotation, IRoot>, payload: IFactSynonyms = getters.getFactSynonyms()): void {
    commit(SET_SYNONYMS, payload);
  },

  populateBasicInfoToAllQuote({dispatch, state}: ActionContext<IQuotation, IRoot>) {
    for (const key in state.products) {
      if (Object.prototype.hasOwnProperty.call(state.products, key)) {
        dispatch('populateBasicInfoToSpecificQuote', key);
      }
    }
  },

  populateBasicInfoToSpecificQuote({commit, state, rootState}: ActionContext<IQuotation, IRoot>, productID: string) {
    const basicInfo: any[] = rootState.proposal.basicInfo;
    const quotedProduct: IShiftProductData = state.products[productID];
    const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);
    const factObjects: IFactObject[] = [];

    if (basicInfo && Array.isArray(basicInfo)) {
      basicInfo.forEach((info: any) => {
        const factIds: string[] = info.shiftFactIds;
        const factValue: string = info.value;

        factIds.forEach(async (factID: string) => {
          if (factID && factValue) {
            factObjects.push({factID, factValue});
          }
        });
      });
    }

    quoteProduct.updateMultipleFact(factObjects).then((quoted: IShiftProductData) => {
      commit(SET_QUOTATION_PRODUCT, quoted);
      commit(SET_QUOTATION_CACHE, quoted);
    });
  },

  async updateFact({commit, state, getters, rootState, dispatch}: ActionContext<IQuotation, IRoot>, payload: IFactUpdatePayload): Promise<void> {
    const currentFact = getters.getFactsByProductIDAndFactID(payload.productID, payload.fact.id);
    if (!payload.autofill && currentFact && currentFact.currentValue === payload.value) {
      return;
    }

    // set value dirty
    dispatch('app/setValue', {code: 'dirty', value: true}, { root: true});

    // Update fact
    const quotedProduct: IShiftProductData = state.products[payload.productID];
    if (quotedProduct) {
      const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);


      let updates: IFactObject[] = [{
        factID: payload.fact.id,
        factValue: payload.value
      }];
      if (payload.autofill) {
        // Fact integrations with look up autofill services
        const integrations = _Get(rootState.app.config.shiftMappings, 'FACT_INTEGRATIONS', {});
        let autofills = [];
        let fieldErrorID: string | null = null;
        let fieldID: string | null = null;
        const key = _Find(Object.keys(integrations), (integration) => _Some(integrations[integration], (fact) => {
          if (payload.fact.id.includes(fact.field)) {
            autofills = fact.autofill;
            fieldID = fact.field;
            fieldErrorID = fact.field.replaceAll('.', '_').replaceAll(':', '-');
            return true;
          }
        }));
        let errorMsg: string | null = null;
        switch (key) {
          case 'CARJAM':
            const response = await CarjamService.searchByPlate(payload.value);
            if (response) {
              const responseData: any = JSON.parse(response);
              if (responseData) {
                if (responseData.code === -1) {
                  errorMsg = responseData.message;
                } else if (responseData && fieldID) {
                  const vehicleData = responseData.idh.vehicle;
                  updates = [...updates, ..._Map(autofills, (autofill, keyword) => {
                    return {
                      factID: payload.fact.id.replace(fieldID, autofill),
                      factValue: vehicleData[keyword]
                    };
                  })];
                }
              }
            }
            break;
        }
        if (errorMsg && fieldErrorID && payload.vm) {
          // Re-using textfield error message display ISSUE: gets overwritten by veevalidate
          // Handle error msg if required in future, currently veevalidate will overwrite with fact validation
          // payload.vm.$addError(payload.fact._UUID, errorMsg);
          payload.vm.$dialog.open({
            icon: _Get(payload.vm.cms, 'theme.errorIcon'),
            text: payload.vm.$t('notifications.integration'),
            buttons: [
              {
                type: 'main',
                text: payload.vm.$t('button.ok')
              }
            ],
            persistent: true
          });
          updates = [...updates, ..._Map(autofills, (autofill, keyword) => {
            return {
              factID: payload.fact.id.replace(fieldID, autofill),
              factValue: ''
            };
          })];
        } else {
          // payload.vm.$resetFactErrors(payload.fact._UUID);
        }
      }
      const forceUpdate = payload.fact.formatType === 'file_reference';
      quoteProduct.updateMultipleFact(updates, forceUpdate).then((quoted: IShiftProductData) => {
        commit(SET_QUOTATION_PRODUCT, quoted);
        commit(SET_QUOTATION_CACHE, quoted);
      });
    }

    // Update synonym facts
    if (!payload.noSynonym) {
      dispatch('updateFactSynonyms', payload);
    }

    // update basic info
    dispatch('proposal/updateBasicInfo', { factID: payload.fact.id, value: payload.value}, { root: true });
  },

  async updateMultiFact({commit, state, dispatch}: ActionContext<IQuotation, IRoot>, payload: IMultiFactUpdatePayload): Promise<void> {
    const quotedProduct: IShiftProductData = state.products[payload.productID];
    if (quotedProduct) {
      const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);
      quoteProduct.updateMultipleFact(payload.facts).then((quoted: IShiftProductData) => {
        commit(SET_QUOTATION_PRODUCT, quoted);
        commit(SET_QUOTATION_CACHE, quoted);
      });
    }

    // Update synonym facts
    dispatch('updateFactSynonyms', payload);

    // set value dirty
    dispatch('app/setValue', {code: 'dirty', value: true}, { root: true});
  },

  updateAllProductSynonyms({commit, state, getters, dispatch}: ActionContext<IQuotation, IRoot>): void {
    const allFacts: any[] = getters.getCustomAllFacts();
    for (const key in state.products) {
      if (Object.prototype.hasOwnProperty.call(state.products, key)) {
        dispatch('updateFactSynonyms', {productID: key, facts: _Filter(allFacts, (fact) => fact._productID !== key && !_IsEmpty(fact.currentValue))});
      }
    }
  },

  updateProductSynonymsByProductID({commit, state, getters, dispatch}: ActionContext<IQuotation, IRoot>, productID: string): void {
    const allFacts: any[] = getters.getCustomAllFacts();
    const filteredFacts = _Filter(allFacts, (fact) => fact._productID !== productID);
    dispatch('updateFactSynonyms', {productID, facts: filteredFacts});
  },

  async updateFactSynonyms({commit, state, getters}: ActionContext<IQuotation, IRoot>, payload: IFactUpdatePayload | IMultiFactUpdatePayload): Promise<void> {
    let data: any[] = [];
    let productLevelUpdate = false;
    if (payload.hasOwnProperty('value')) {
      data = [(payload as IFactUpdatePayload).fact];
    } else {
      data = (payload as IMultiFactUpdatePayload).facts;
      productLevelUpdate = true;
    }

    interface SynonymFactUpdatePayload {
      productID: string;
      factID: string;
      factValue: string;
    }

    const synonymsFacts: SynonymFactUpdatePayload[] = [];
    for (const fact of data) {
      let factCustomID: string = '';
      if ((fact as any)._customID) {
        factCustomID = (fact as any)._customID;
      } else if ((fact as any).id) {
        factCustomID = QuoteProduct.constructFactCustomId(fact.id, fact._productCode);
      } else {
        factCustomID = QuoteProduct.constructFactCustomId(fact.factID, (payload as IMultiFactUpdatePayload).productCode);
        fact._productID = payload.productID;
        fact.currentValue = fact.factValue;
      }
      // create synonyms facts
      const synonyms: string[] | undefined = getters.getFactSynonyms()[factCustomID];
      if (synonyms) {
        for (const synId of synonyms) {
          const isValidCustomID = QuoteProduct.isValidCustomID(synId);
          const factID = QuoteProduct.getFactIdFromCustomID(synId);
          if (isValidCustomID) {
            synonymsFacts.push({
              productID: fact._productID,
              factID,
              factValue: productLevelUpdate ? fact.currentValue : (payload as IFactUpdatePayload).value,
            });
          }
        }
      }
    }

    const groupedSynonymsFacts = _GroupBy(synonymsFacts, 'productID');
    for (const synonymProductID in groupedSynonymsFacts) {
      if (Object.prototype.hasOwnProperty.call(groupedSynonymsFacts, synonymProductID)) {
        for (const productID in state.products) {
          if (Object.prototype.hasOwnProperty.call(state.products, synonymProductID) && productID !== synonymProductID) {
            const quotedProduct: IShiftProductData = state.products[productID];
            const factsToUpdate: IFactObject[] = groupedSynonymsFacts[synonymProductID].map((symFact: SynonymFactUpdatePayload) => {
              return {
                factID: symFact.factID,
                factValue: symFact.factValue,
              };
            });

            const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);
            await quoteProduct.updateMultipleFact(factsToUpdate).then( async (quoted: IShiftProductData) => {
              await commit(SET_QUOTATION_PRODUCT, quoted);
              await commit(SET_QUOTATION_CACHE, quoted);
            });
          }
        }
      }
    }
  },

  addInsured({commit, state}: ActionContext<IQuotation, IRoot>, payload: IAddInsuredPayload): void {
    const quotedProduct: IShiftProductData = state.products[payload.productID];
    if (quotedProduct) {
      const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);
      quoteProduct.addInsured(payload).then((quoted: IShiftProductData) => {
        commit(SET_QUOTATION_PRODUCT, quoted);
        commit(SET_QUOTATION_CACHE, quoted);
        if (payload.callback) { payload.callback(); }
      });
    }
  },

  removeInsured({commit, state}: ActionContext<IQuotation, IRoot>, payload: IRemoveInsuredPayload): void {
    const quotedProduct: IShiftProductData = state.products[payload.productID];
    if (quotedProduct) {
      const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);
      quoteProduct.removeInsured(payload).then((quoted: IShiftProductData) => {
        commit(SET_QUOTATION_PRODUCT, quoted);
        commit(SET_QUOTATION_CACHE, quoted);
        if (payload.callback) { payload.callback(); }
      });
    }
  },

  setPolicyDate({commit, state, rootState, dispatch}: ActionContext<IQuotation, IRoot>, payload: ISetPolicyStartDatePayload): void {
    const isCopyCoverageDate: boolean = rootState.app.copyCoverageDate;
    if (isCopyCoverageDate) { // update all product coverage date
      dispatch('setPolicyDateForAllQuotation', payload.date);
    } else { // only update selected product
      const quotedProduct: IShiftProductData = state.products[payload.productID];
      if (quotedProduct) {
        const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);
        quoteProduct.setPolicyStartDate(payload).then((quoted: IShiftProductData) => {
          commit(SET_QUOTATION_PRODUCT, quoted);
          commit(SET_QUOTATION_CACHE, quoted);
        });
      }
    }
  },

  setPolicyDateForAllQuotation({commit, state}: ActionContext<IQuotation, IRoot>, policyStartDate: string): void {
    for (const key in state.products) {
      if (Object.prototype.hasOwnProperty.call(state.products, key)) {
        const quotedProduct: IShiftProductData = state.products[key];
        const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);
        quoteProduct.setPolicyStartDate({productID: quoteProduct.id, date: policyStartDate}).then((quoted: IShiftProductData) => {
          commit(SET_QUOTATION_PRODUCT, quoted);
          commit(SET_QUOTATION_CACHE, quoted);
        });
      }
    }
  },

  async setQuotationProduct({state, commit, dispatch, rootGetters}: ActionContext<IQuotation, IRoot>, payload: ISetQuotationProductPayload): Promise<string | undefined> {
    const rawProducts: IRawProducts = rootGetters['products/getAllProductsWithLatestVersion']();
    const selectedRawProduct: IRawProduct | undefined = rawProducts.find((raw: IRawProduct) => raw.code === payload.productCode);

    if (!selectedRawProduct) {
      console.error('product not available', payload.productCode);
      return undefined;
    } else {
      const { id, code, definition } = selectedRawProduct;
      const quoteProduct = new QuoteProduct(id, code, definition, payload.order);

      const presetFacts = payload.presetFacts || [];
      const guestParty: PolicyPartyModel | undefined = await rootGetters['auth/getGuestParty']();
      const accountId: number = await rootGetters['auth/getAccountId']();
      if (guestParty) {
          const config = await PolicyService.getPolicyPartyMappingConfig(accountId);
          if (Object.keys(config.linkMappingConfig).includes('Applicant')) {
            const mappings = config.linkMappingConfig.Applicant.fieldMapping;
            Object.keys(mappings).forEach((key) => {
              const factIds = mappings[key];
              factIds.forEach((factID) => {
                presetFacts.push({factID, factValue: guestParty[key]});
              });
            });
          }
      }

      await quoteProduct.init(presetFacts).then(async (quoted: IShiftProductData) => {
        commit(SET_QUOTATION_PRODUCT, quoted);
        commit(SET_QUOTATION_CACHE, quoted);

        await dispatch('proposal/addProduct', {id, code, definition}, { root: true });
        await dispatch('populateBasicInfoToSpecificQuote', quoted.id);
        await dispatch('proposal/updateContactInfo', presetFacts.map((presetFact) => {
          return { factID: presetFact.factID, value: presetFact.factValue };
        }), { root: true });
      });
      return id;
    }
  },

  setQuotationProductByProductID({state, commit, dispatch, rootState, rootGetters}: ActionContext<IQuotation, IRoot>, payload: any): void {
    const rawProducts: IRawProducts = rootGetters['products/getProductsSortedLatest']();
    const selectedRawProduct: IRawProduct | undefined = rawProducts.find((raw: IRawProduct) => raw.id === payload.productID);

    if (!selectedRawProduct) {
      console.error('product id not available', payload.productID);
    } else {
      const { id, code, definition } = selectedRawProduct;
      const quoteProduct = new QuoteProduct(id, code, definition, 100, new Date().getTime(), payload.storedSession, payload.policyId);

      quoteProduct.init().then((quoted: IShiftProductData) => {
        commit(SET_QUOTATION_PRODUCT, quoted);
        commit(SET_QUOTATION_CACHE, quoted);

        dispatch('proposal/addProduct', {id, code, definition}, { root: true });
        dispatch('populateBasicInfoToSpecificQuote', quoted.id);
        dispatch('proposal/updateContactInfo', quoted.facts.facts.map((fact) => {
          return { factID: fact.id, value: fact.currentValue};
        }), { root: true });
      });
    }
  },

  async restoreFromSavedProposal({dispatch}: ActionContext<IQuotation, IRoot>,  quotes: any[]): Promise<void> {
    await dispatch('reset');
    for (const quote of quotes) {
      await dispatch('quotation/setQuotationProductByProductID', { productID: quote.productId, storedSession: quote.productConfig}, { root: true });
    }
    dispatch('setSynonyms').then(() => dispatch('updateAllProductSynonyms'));
  },

  async restoreFromPolicyStatus({dispatch}: ActionContext<IQuotation, IRoot>,  policyStatuses: any[]): Promise<void> {
    await dispatch('reset');
    policyStatuses.forEach(async (policyStatus: any) => {
      await dispatch('quotation/setQuotationProductByProductID', { productID: policyStatus.productId, storedSession: policyStatus.config, policyId: policyStatus.id}, { root: true });
    });
    dispatch('setSynonyms').then(() => dispatch('updateAllProductSynonyms'));
  },

  async restoreFromCache({commit, state, rootState, rootGetters, dispatch}: ActionContext<IQuotation, IRoot>): Promise<void> {
    const rawProducts: IRawProducts = rootGetters['products/getAllProductsWithLatestVersion']();
    state.caches.forEach(async (cache: IQuotationCache) => {
      const selectedRawProduct: IRawProduct | undefined = rawProducts.find((raw: IRawProduct) => raw.id === cache.productID);

      if (!selectedRawProduct) {
        console.error('product not available', cache.productID);
      } else {
        const { id, code, definition } = selectedRawProduct;
        const quoteProduct = new QuoteProduct(id, code, definition, cache.order, cache.index, cache.storedSession);
        await quoteProduct.init().then((quoted: IShiftProductData) => {
            commit(SET_QUOTATION_PRODUCT, quoted);
            // dispatch('proposal/addProduct', {id, code, definition}, { root: true });
        });
      }
    });
  },

  setShiftResult({commit}: ActionContext<IQuotation, IRoot>, shiftResults: IShiftResult[] = []): void {
    commit(SET_SHIFT_RESULTS, shiftResults);
  },

  async build({state, rootState, rootGetters, dispatch}: ActionContext<IQuotation, IRoot>, payload: IBuildPayload): Promise<void> {
    if (!rootState.proposal.basicInfo || !rootState.proposal.basicInfo.length) {
      const basicInfoForm = await rootGetters['cms/loadBasicInfoForm'](rootState.app.targetSegment);
      await dispatch('proposal/setBasicInfo', basicInfoForm, { root: true });
    }

    if (!payload.resetQuotationForCache && state.caches.length > 0) {
      await dispatch('restoreFromCache');
    } else if (rootState.app.isRenewal) {
      await dispatch('reset');
      await dispatch('rebuildRenewalProducts');
    } else {
      await dispatch('reset');

      const products = _Uniq(_Map(rootGetters['products/getProductsSortedLatest'](), (p) => p.code));
      const selectedProducts: string[] | string = payload.products ? payload.products : _OrderBy(rootState.app.products, (f: any) => _IndexOf(products, f));
      await dispatch('buildNewProducts', {selectedProducts, presetFacts: payload.presetFacts});
    }
    // dispatch('setSynonyms').then(() => dispatch('updateAllProductSynonyms'));
  },

  async buildNewProducts({dispatch}: ActionContext<IQuotation, IRoot>, payload: {selectedProducts: string[] | string, presetFacts: IPresetFactPayload[]}): Promise<void> {
    // handing presetFact and affiliate code
    const expectedPresetFacts: IFactObject[] = [];
    if (store.state.app.affiliateCode) {
      expectedPresetFacts.push({factID: 'applicant.referral.code', factValue: store.state.app.affiliateCode});
    }

    if (Array.isArray(payload.selectedProducts)) {
      let order = 1;
      payload.selectedProducts.forEach((productCode: string) => {
        if (payload.presetFacts) {
          payload.presetFacts.forEach((presetFact: IPresetFactPayload) => {
            if (presetFact.productCode === productCode) {
              expectedPresetFacts.push({ factID: presetFact.factID, factValue: presetFact.factValue});
            }
          });
        }
        dispatch('quotation/setQuotationProduct', { productCode, order, presetFacts: expectedPresetFacts }, { root: true });
        order++;
        if (expectedPresetFacts.length > 0) {
          // set value dirty
          dispatch('app/setValue', {code: 'dirty', value: true}, { root: true});
        }
      });
    } else {
      if (payload.presetFacts) {
        payload.presetFacts.forEach((presetFact: IPresetFactPayload) => {
          if (presetFact.productCode === payload.selectedProducts) {
            expectedPresetFacts.push({ factID: presetFact.factID, factValue: presetFact.factValue});
          }
        });
      }
      dispatch('quotation/setQuotationProduct', { productCode: payload.selectedProducts, presetFacts: expectedPresetFacts }, { root: true });
      if (expectedPresetFacts.length > 0) {
        // set value dirty
        dispatch('app/setValue', {code: 'dirty', value: true}, { root: true});
      }
    }
  },

  async rebuildRenewalProducts({state, rootState, rootGetters, dispatch, commit}: ActionContext<IQuotation, IRoot>): Promise<void> {
    const rawProducts: IRawProducts = rootGetters['products/getProductsSortedLatest']();
    const selectedProductCodes: string[] | string  = rootState.app.products;
    const renewalProductIds: any = _Get(rootState.app.renewalResponse, 'renewalIds', {});
    const renewalConfigs: any = _Get(rootState.app.renewalResponse, 'configs', {});
    // renewal product handling
    const renewalIds: any = {};
    const renewalProductCodes: string[] = [];
    for (const quoteId in renewalProductIds) {
      if (!quoteId) {
        continue;
      }
      const renewalProductID = renewalProductIds[quoteId];
      let selectedRawProduct: IRawProduct | undefined;
      // let currentStartDate: any;
      // let policyStartDate: any = moment();
      // const renewalProduct = _Find(rawProducts, (product) => product.id === renewalProductID);
      // if (renewalProduct) {
      //   const renewalSession = productEngine.newSessionFromConfig(`${renewalProduct.definition}`, renewalConfigs[renewalProductID]);
      //   const renewalPolicy = renewalSession.describePolicy();
      //   if (renewalPolicy && renewalPolicy.policyStartDate) {
      //     policyStartDate = moment(renewalPolicy.policyStartDate, 'YYYY-MM-DD');
      //   }
      // }
      rawProducts.forEach((raw: IRawProduct) => {
        // if (!renewalProductCodes.includes(raw.code)) {
        //   return;
        // }
        if (renewalProductID === raw.id) {
          selectedRawProduct = raw;
          renewalIds[quoteId] = raw.id;
          return false;
        }

        // product selection based on coverage dates
        // const rawCoverageStartDate = moment(raw.availableFrom, 'YYYY-MM-DD');
        // const rawCoverageEndDate = moment(raw.availableTo, 'YYYY-MM-DD');
        // // Valid product coverage period with earliest start date
        // if (policyStartDate >= rawCoverageStartDate && (rawCoverageStartDate === rawCoverageEndDate || policyStartDate < rawCoverageEndDate) && (!currentStartDate || rawCoverageStartDate > currentStartDate)) {
        //   selectedRawProduct = raw;
        //   currentStartDate = rawCoverageStartDate;
        // }
      });
      if (!selectedRawProduct) {
        console.error('product for renewal not available', renewalProductID);
      } else if (selectedProductCodes.includes(selectedRawProduct.code) || _Get(rootState.app, 'renewalSkipCatalogue', false)) {
        const { id, code, definition } = selectedRawProduct;
        renewalProductCodes.push(code);
        const quoteProduct = new QuoteProduct(id, code, definition, 100, new Date().getTime(), renewalConfigs[renewalProductID]);
        await quoteProduct.init().then( async (quoted: IShiftProductData) => {
            commit(SET_QUOTATION_PRODUCT, quoted);
            dispatch('setSynonyms');
            dispatch('proposal/addProduct', {id, code, definition}, { root: true });
            // dispatch('setPolicyDate', {date: policyStartDate.format('YYYY-MM-DD'), productID: id});
        });
      }
    }
    dispatch('app/setRenewalResponse', {
      ...rootState.app.renewalResponse,
      renewalIds
    }, {root: true});

    // new product selected
    let selectedProductCodesInArray: string[] = Array.isArray(selectedProductCodes) ? selectedProductCodes : [selectedProductCodes];
    const products = await _Uniq(_Map(rootGetters['products/getProductsSortedLatest'](), (p) => p.code));
    selectedProductCodesInArray = await _OrderBy(selectedProductCodesInArray, (f: any) => _IndexOf(products, f));
    selectedProductCodesInArray.forEach(async (selectedProductCode: string) => {
      if (!renewalProductCodes.includes(selectedProductCode)) {
        await dispatch('quotation/createQuotationProduct', selectedProductCode, { root: true });
      }
    });

    await dispatch('updateBasicInfoByRenewalFacts', JSON.parse(_Values(renewalConfigs)[0]).factValues);
  },

  async updateBasicInfoByRenewalFacts({dispatch}: ActionContext<IQuotation, IRoot>, renewalFactValues: any): Promise<void> {
    for (const factKey of Object.keys(renewalFactValues)) {
      dispatch('proposal/updateBasicInfo', { factID: factKey, value: renewalFactValues[factKey]}, { root: true });
    }
  },

  async updateRenewalQuote({commit, state, rootState}: ActionContext<IQuotation, IRoot>) {
    for (const key in state.products) {
      if (Object.prototype.hasOwnProperty.call(state.products, key)) {
        const quotedProduct: IShiftProductData = state.products[key];

        const quoteInRenewalResponse: any = _.find(rootState.app.renewalResponse.raw, (q) => {
          const productId = rootState.app.renewalResponse.renewalIds[q.id];
          return productId === key && (q.type === Util.SHIFT_QUOTE_TYPE_RENEWAL || q.type === Util.SHIFT_QUOTE_TYPE_NEW);
        });
        if (quoteInRenewalResponse) {
          const quoteProduct = new QuoteProduct(quotedProduct.id, quotedProduct.code, quotedProduct.definition, quotedProduct.order, quotedProduct.index,  quotedProduct.storedSession);
          await quoteProduct.setRenewalType();
        }
      }
    }
  },

  setManualPricing({commit}: ActionContext<IQuotation, IRoot>, payload: boolean): void {
    commit(SET_MANUAL_PRICING, payload);
  },

  setRequiredReview({commit}: ActionContext<IQuotation, IRoot>, payload: boolean): void {
    commit(SET_REVIEW_REQUIRED, payload);
  },

  // only used for External Pricing to set underwriting and pricing from external source.
  setQuotation({commit}: ActionContext<IQuotation, IRoot>, payload: any): void {
    commit(SET_QUOTATION, payload);
  },

};

export default actions;
