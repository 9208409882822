import { IFactUpdatePayload, IAddInsuredPayload, IRemoveInsuredPayload, ISetPolicyStartDatePayload, IFactObject } from './../interfaces/IQuotation';
import ProductEngineUtils from '@/utils/ProductEngineUtils';
import { IMap, IShiftProductData } from '@/interfaces';
import Util from '@/utils/Util';
import _ from 'lodash';
import vuexApp from '@/store/modules/app';
import store from '@/store';
import { formatCurrency } from '@/filters';
import PEClientJs from 'product-engine-client-js';
import config from '@/config';
import PEAxiosInstance from '@/utils/PEAxiosInstance';
import EventBus from '@/common/EventBus';

interface EngineObj {
    [id: string]: any;
}

const engineBackpack: EngineObj[] = [];

export default class QuoteProduct implements IShiftProductData {

    public static FACT_TYPE_MAP: IMap = {
        GROUP: 'FactLabel',
        BOOLEAN: 'FactSelection',
        DATE: 'FactDate',
        LIST: 'FactList',
        TEXT: 'FactText',
        MULTILINE_TEXT: 'FactMultilineText',
        EMAIL: 'FactText',
        NUMBER: 'FactNumber',
        PHONE_NUMBER: 'FactPhoneNumber',
        ADDRESS_AUTO_COMPLETE: 'FactAddressAutoComplete',
        COMPANY_AUTO_COMPLETE: 'FactCompanyAutoComplete'
    };

    public static SHIFT_STATUS_TYPE: IMap = {
        SHIFT_UPDATED: 'SHIFT-UPDATED',
        SHIFT_APPROVED: 'SHIFT-APPROVED',
        SHIFT_REJECTED: 'SHIFT-REJECTED',
    };

    public static constructFactCustomId(factID: string, productCode: string): string {
        return 'PRODUCT:' + productCode + '#' + factID;
    }

    public static getProductCodeFromCustomID(customID: string): string {
        return customID.substring(customID.lastIndexOf(':') + 1, customID.lastIndexOf('#'));
    }

    public static isValidCustomID(customID: string): boolean {
        return customID.match(/^PRODUCT:/) ? true : false;
    }

    public static getFactIdFromCustomID(customID: string): string {
        return customID.replace(/.*#/, '');
    }

    public order: number;
    public index: number;
    public id: string;
    public code: string;
    public policyStartDate: string | undefined;
    public policyEndDate: string | undefined;
    public coverageStartDate: string | undefined;
    public coverageEndDate: string | undefined;
    public definition: string;
    public facts: any | undefined = undefined;
    public customFacts: any | undefined = undefined;
    public price: any | undefined = undefined;
    public policy: any | undefined = undefined;
    public product: any | undefined = undefined;
    public validationData: any | undefined = undefined;
    public underwriting: any | undefined = undefined;
    public storedSession: string = '';
    public customDescription: any | undefined = undefined;
    public customProductTree: any | undefined = undefined;
    public customAllFacts: any[] = [];
    public customUnderwritingFacts: any[] = [];
    public isSeparateCoveragePeriod: boolean = false;
    public isReviewRequired: boolean = false;
    public currentPrice: number = 0;
    public policyNumber: string | undefined;
    public policyId: string | undefined;

	constructor(
        id: string,
        code: string,
        definition: string,
        // for products selected from landing page, the order it set start from 1.
        // for products added later, the order is with default value 100.
        order: number = 100,
        index: number = new Date().getTime(),
        storedSession: string = '',
        policyId?: string
    ) {
        this.resolveFactTree = this.resolveFactTree.bind(this);
        this.id = id;
        this.code = code;
        this.policyId = policyId;
        this.definition = definition;
        this.order = order;
        this.index = index;
        if (storedSession) {
            this.storedSession = storedSession;
        }
    }

    public async init(presetFacts: IFactObject[] = []) {

        // remove engine if add a new product with same id
        if (engineBackpack[this.id]) {
            delete engineBackpack[this.id];
        }

        const session: any = await this.getSession();

        // preset fact value if exists
        if (presetFacts) {
            presetFacts.forEach(async (presetFact: IFactObject) => {
                await session.setFactValue(presetFact.factID, presetFact.factValue);
            });
        }
        await this.recalculate(session);

        return this;
    }

    public async updateFact(payload: IFactObject) {
        const session: any = await this.getSession();
        try {
            await session.setFactValue(payload.factID, payload.factValue);
        } catch (error) {
            console.error(error);
        }
        await this.recalculate(session);

        return this;
    }

    public async updateMultipleFact(payload: IFactObject[], forced?: boolean) {
        const session: any = await this.getSession();

        for (const p of payload) {
           if (forced || (p.factValue && typeof p.factValue !== undefined)) { await session.setFactValue(p.factID, p.factValue); }
        }
        await this.recalculate(session);

        return this;
    }

    public async addInsured(payload: IAddInsuredPayload) {
        const session: any = await this.getSession();
        await session.addInsured(payload.type);
        await this.recalculate(session);

        return this;
    }

    public async removeInsured(payload: IRemoveInsuredPayload) {
        const session: any = await this.getSession();
        await session.removeInsured(payload.type, payload.insuredID);
        await this.recalculate(session);

        return this;
    }

    public async setPolicyStartDate(payload: ISetPolicyStartDatePayload) {
        const session: any = await this.getSession();
        await session.setPolicyStartDate(payload.date);
        await this.recalculate(session);

        return this;
    }

    public async setRenewalType() {
        const session: any = await this.getSession();
        await session.setLifecycleType('RENEWAL');
        await session.setPricingType('RENEWAL');
        await this.recalculate(session);

        return this;
    }

    public async getFactValue(id: string) {
      const session: any = await this.getSession();
      return session.getFactValue(id);
    }

    public async getSession() {
        if (!engineBackpack[this.id]) {
            const engine = new PEClientJs.ProductEngine({
                axiosInstance: PEAxiosInstance,
                productId: this.id,
                policyId: this.policyId,
                config: this.storedSession ? this.storedSession : null,
                baseUrl: window.location.origin + config.api.publicPath,
                beforeSyncCallback: () => EventBus.$emit('PE_IN_PROGRESS'),
                afterSyncCallback: () => EventBus.$emit('PE_COMPLETED')
            }) as any;

            engineBackpack[this.id] = engine;
        }
        return engineBackpack[this.id];
    }

    // use to describe product in order to get product information without affecting the product state on engineBackPack
    // for example, use this to form the Overdose data layer
    public async describeProduct() {
        const engine = new PEClientJs.ProductEngine({
            axiosInstance: PEAxiosInstance,
            productId: this.id,
            config: this.storedSession ? this.storedSession : null,
            baseUrl: window.location.origin + config.api.publicPath
        }) as any;
        this.recalculate(engine, false);
    }

    private async recalculate(session: any = this.getSession(), clearEngineIfError = true) {
        const vuexStorageKey = Object.keys(sessionStorage).find((key) => key.startsWith(config.storageKey + '-')) || '';
        const storeObj = sessionStorage.getItem(vuexStorageKey) || '';
        const storeObjFromSession =  JSON.parse(storeObj);
        const language = storeObjFromSession?.app?.language;

        const appLanguage: string = language ? language : _.get(vuexApp, 'state.language', '');
        if (appLanguage) {
            await session.setLanguage(appLanguage);
        }


        try {
            this.policy = await session.describePolicy();
        } catch (error) {
            console.error('error describe policy');
            if (engineBackpack[this.id] && clearEngineIfError) { delete engineBackpack[this.id]; }
        }

        try {
            this.facts = await session.describeFacts();
        } catch (error) {
            console.error('error describe fact');
            if (engineBackpack[this.id] && clearEngineIfError) { delete engineBackpack[this.id]; }
        }

        try {
            this.product = await session.describeProduct();
        } catch (error) {
            console.error('error describe product');
            if (engineBackpack[this.id] && clearEngineIfError) { delete engineBackpack[this.id]; }
        }

        try {
            this.validationData = await session.validate();
        } catch (e) {
            this.validationData = {};
            if (engineBackpack[this.id] && clearEngineIfError) { delete engineBackpack[this.id]; }
            console.error('calculation: validation failed');
        }

        try {
            this.price = await session.calculatePrice();
        } catch (e) {
            this.price = {};
            if (engineBackpack[this.id] && clearEngineIfError) { delete engineBackpack[this.id]; }
            console.error('calculation: pricing failed');
        }

        try {
            this.underwriting = await session.underwrite();
        } catch (e) {
            this.price = {};
            if (engineBackpack[this.id] && clearEngineIfError) { delete engineBackpack[this.id]; }
            console.error('calculation: underwriting failed');
        }

        const additionalFormats = _.get(store.state.app.config.shiftMappings, 'PRICE_BUCKET_VIEW_MODIFIER', []);

        _.forEach(additionalFormats[this.code], (data: any, modifier: string) => {
          const bucket = _.find(this.price.buckets, (b) => b.id === modifier);
          let value = 0;
          if (!bucket) {
            return;
          }
          if (_.isNaN(parseFloat(data.value))) {
            const components = data.value.split('+');
            _.forEach(components, (compInfo) => {
              const info = compInfo.split('=');
              const ids = info[0].split('.');
              for (const comp of this.price.componentResult) {
                if (comp.id !== ids[0]) {
                  continue;
                }
                const subBucket = _.find(comp.buckets, (b) => b.id === ids[1]);
                if (subBucket) {
                  value += subBucket.totalPrice * parseFloat(info[1]);
                  break;
                }
              }
            });
          }
          bucket.additional = ` (${data.label}${formatCurrency(value > 0 ? value : data.value * bucket.totalPrice, '$')})`;
        });

        this.policyStartDate = this.policy.policyStartDate || undefined;
        this.policyEndDate = this.policy.policyEndDate || undefined;


        try {
            await this.constructCustomFacts();

            this.customDescription = {
                description: { product: this.product, facts: this.customFacts, policy: this.policy },
                calculation: { valudation: this.validationData, price: this.price, underwriting: this.underwriting }
            };

            this.customProductTree = await ProductEngineUtils.getProductTree(this.customDescription);

            await this.constructCustomAllFacts();

            // Construct Custom Underwriting Facts
            this.customUnderwritingFacts = this.customProductTree.underwritingFacts.map((f: any) => this.addCustomFactAttribute(f));

            this.coverageStartDate = this.policy.coverageStartDate || this.getFactValueByID(Util.COMPONENT_START_DATE_ID);
            this.coverageEndDate = this.policy.coverageEndDate || this.getFactValueByID(Util.COMPONENT_END_DATE_ID);

            this.isSeparateCoveragePeriod = Util.isSeparateCoveragePeriod(this.code);
            this.isReviewRequired = this.price.needsManualPricing;
            this.currentPrice = this.price.valid ? this.price.totalPrice : 0;

            this.storedSession = await session.serializeConfig();
        } catch (error) {
            console.error(error, 'error recalculate');
        }
    }

    private constructCustomFacts() {
        // Construct custom facts
        const theCustomFacts = this.facts.facts.map((f: any) => this.addCustomFactAttribute(f));
        const theFactsTree = this.facts.factsTree.map((tree: any) => this.resolveFactTree(tree));
        const theComponents = this.facts.components.map((component: any) => {
            const insured = component.insured.map((i: any) => {
                return {
                    ...i,
                    facts: i.facts.map((f: any) => this.addCustomFactAttribute(f)),
                    factsTree: i.factsTree.map((tree: any) => this.resolveFactTree(tree)),
                };
            });
            return {
                ...component,
                facts: component.facts.map((f: any) => this.addCustomFactAttribute(f)),
                factsTree: component.factsTree.map((tree: any) => this.resolveFactTree(tree)),
                insured
            };
        });

        this.customFacts = {
            components: theComponents,
            facts: theCustomFacts,
            factsTree: theFactsTree
        };
    }

    private constructCustomAllFacts() {
        const productLevelFacts = this.customProductTree.allFacts.map((f: any) => this.addCustomFactAttribute(f));

        const componentLevelAllFacts: any[] = [];
        for (const component of this.customProductTree.components) {
            component.allFacts.forEach((f: any) => {
                const newFact = this.addCustomFactAttribute(f);
                componentLevelAllFacts.push(newFact);
            });
            for (const asset of component.assets) {
                asset.allFacts.forEach((f: any) => {
                    const newFact = this.addCustomFactAttribute(f);
                    componentLevelAllFacts.push(newFact);
                });
            }
        }

        this.customAllFacts = [
            ...productLevelFacts,
            ...componentLevelAllFacts
        ];
    }

    private addCustomFactAttribute(fact: any) {
        const newAttribute = {
            _productCode: this.code,
            _productID: this.id,
            _customID: QuoteProduct.constructFactCustomId(fact.id, this.code),
            _UUID: Util.generateUid(this.code, fact.id)
        };
        return Object.assign(newAttribute, fact);
    }

    private getFactValueByID(id: string) {
        const selectedFact: any = this.facts.facts.find((fact: any) => fact.id === id);
        return selectedFact && selectedFact.currentValue || undefined;
    }

    private resolveFactTree(factTree: any) {
        const self = this;
        const currentTreeChildren = _.get(factTree, 'children', []);
        const children: any[] = [];
        currentTreeChildren.forEach((factTreeChild: any) => {
            children.push(self.resolveFactTree(factTreeChild));
        });

        return {
            ...factTree,
            children,
            fact: this.addCustomFactAttribute(factTree.fact)
        };
    }

}
