export const SET_FACTS: string = 'SET_FACTS';
export const SET_QUOTATION_PRODUCT: string = 'SET_QUOTATION_PRODUCT';
export const SET_QUOTATION_CACHE: string = 'SET_QUOTATION_CACHE';
export const DELETE_QUOTATION_PRODUCT: string = 'DELETE_QUOTATION_PRODUCT';
export const DELETE_QUOTATION_CACHE: string = 'DELETE_QUOTATION_CACHE';
export const RESET_QUOTATION: string = 'RESET_QUOTATION';
export const SET_SYNONYMS: string = 'SET_SYNONYMS';
export const SET_SHIFT_RESULTS: string = 'SET_SHIFT_RESULTS';
export const SET_MANUAL_PRICING: string = 'SET_MANUAL_PRICING';
export const SET_QUOTATION: string = 'SET_QUOTATION';
export const UPDATE_QUOTATION_FILES: string = 'UPDATE_QUOTATION_FILES';
export const REMOVE_QUOTATION_FILES: string = 'REMOVE_QUOTATION_FILES';
export const SET_REVIEW_REQUIRED: string = 'SET_REVIEW_REQUIRED';
